<template>
  <section
    class="home-login-row full-width"
    :class="{ 'login-with': isAuthenticated }"
  >
    <div class="divider-design" />
    <div class="container">
      <div class="home-login-section-row flex" v-if="isAuthenticated">
        <div class="home-login-section-left">
          <h2>
            <label class="block">
              <picture class="image w-full block">
                <source
                  media="(min-width: 767px)"
                  :srcset="getMainIconDesktop"
                />
                <img :src="getMainIconMobile" :alt="getMainIconAlt" />
              </picture>
            </label>
            <span class="first-name">{{ userGetters.getFirstName(user) }}</span>
            <span class="last-name">{{ userGetters.getLastName(user) }}</span>
          </h2>
          <div>
            <SfButton class="btn-yellow" @click="handleAccountLogout(true)">
              {{ $t('Log out') }}
            </SfButton>
          </div>
        </div>
        <div class="home-login-section-right relative">
          <div class="home-login-box flex">
            <div class="home-login-col">
              <nuxt-link :to="localePath('')">
                <div class="image w-full block" @click="toggleWishlistSidebar">
                  <MyFavorites />
                </div>
                <p>{{ $t('I miei preferiti') }}</p>
              </nuxt-link>
            </div>
            <div class="home-login-col">
              <nuxt-link :to="localePath('/my-account/i-miei-documenti')">
                <div class="image w-full block">
                  <MyDocuments />
                </div>
                <p>{{ $t('My Documents') }}</p>
              </nuxt-link>
            </div>
            <div class="home-login-col">
              <nuxt-link :to="localePath('/cart')">
                <div class="image w-full block">
                  <MyCart />
                </div>
                <p>{{ $t('My cart') }}</p>
              </nuxt-link>
            </div>
            <div class="home-login-col">
              <nuxt-link :to="localePath('/area-download')">
                <div class="image w-full block">
                  <DownloadArea />
                </div>
                <p>{{ $t('Download area') }}</p>
              </nuxt-link>
            </div>
            <div class="home-login-col">
              <nuxt-link :to="localePath('/my-account/i-miei-dati')">
                <div class="image w-full block">
                  <MyData />
                </div>
                <p>{{ $t('My data') }}</p>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
      <div class="home-login-section-row flex" v-else>
        <div class="home-login-section-left">
          <h2 class="font-">
            <label class="block">
              <picture class="image w-full block">
                <source
                  media="(min-width: 767px)"
                  :srcset="getMainIconDesktop"
                />
                <img :src="getMainIconMobile" :alt="getMainIconAlt" />
              </picture>
            </label>
            {{ $t(getMainTitle) }}
          </h2>
          <p>
            {{ $t(getMainRegisterText) }}
          </p>
          <div>
            <SfButton class="btn-yellow" @click="handleAccountClick(true)">
              {{ $t(getMainRegisterCtaLabel) }}
            </SfButton>
            <p class="registered-user">
              {{ $t(getMainLoginText) }}
            </p>
            <SfButton @click="handleAccountClick(false)">
              {{ $t(getMainLoginCtaLabel) }}
            </SfButton>
          </div>
        </div>
        <div class="home-login-section-right relative">
          <div class="home-login-box flex">
            <div
              class="home-login-col"
              v-for="(item, index) in getSections"
              :key="'homepage-login-section-' + index"
            >
              <nuxt-link
                :to="
                  localePath(item.link && item.link.url ? item.link.url : '')
                "
              >
                <picture class="image w-full block">
                  <source
                    media="(min-width: 767px)"
                    :srcset="
                      item.icon && item.icon.desktop ? item.icon.desktop : ''
                    "
                  />
                  <img
                    :src="item.icon && item.icon.mobile ? item.icon.mobile : ''"
                    :alt="item.icon && item.icon.alt ? item.icon.alt : ''"
                  />
                </picture>
                <p v-html="item.text ? item.text : ''"></p>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  useRouter,
  useContext,
  ref,
  onMounted,
} from '@nuxtjs/composition-api';
import { useUiState, useUser } from '~/composables';
import { userGetters } from '@gemini-vsf/composables';
import { SfButton } from '@storefront-ui/vue';
import {
  MyFavorites,
  MyData,
  MyCart,
  MyDocuments,
  DownloadArea,
} from '~/components/General/Icons';

export default defineComponent({
  name: 'HomeLogin',
  components: {
    SfButton,
    MyFavorites,
    MyData,
    MyCart,
    MyDocuments,
    DownloadArea,
  },
  props: {
    accountData: {
      type: Object,
      default: () => {},
      validator: (value) => typeof value === 'object',
    },
  },
  setup(props) {
    const router = useRouter();
    const { app } = useContext();
    const { toggleLoginAndRegisterModal, toggleWishlistSidebar } = useUiState();
    const { isAuthenticated, logout, user } = useUser();
    const handleAccountClick = async (displayRegistration = false) => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginAndRegisterModal(displayRegistration ? 'register' : 'login');
      }
    };
    const handleAccountLogout = async () => {
      await logout();
    };
    const getMainTitle = ref();
    const getMainIconDesktop = ref();
    const getMainIconMobile = ref();
    const getMainIconAlt = ref();
    const getMainRegisterText = ref();
    const getMainRegisterCtaLink = ref();
    const getMainRegisterCtaLabel = ref();
    const getMainLoginText = ref();
    const getMainLoginCtaLink = ref();
    const getMainLoginCtaLabel = ref();
    const getSections = ref();

    onMounted(() => {
      getMainTitle.value =
        props.accountData?.data?.main?.title || 'Create an account';
      getMainIconDesktop.value =
        props.accountData?.data?.main?.icon?.desktop || '';
      getMainIconMobile.value =
        props.accountData?.data?.main?.icon?.mobile || '';
      getMainIconAlt.value = props.accountData?.data?.main?.icon?.alt || '';
      getMainRegisterText.value =
        props.accountData?.data?.main?.register?.text ||
        'Fake text that invites you to register.';
      getMainRegisterCtaLink.value =
        props.accountData?.data?.main?.register?.cta?.url || '/';
      getMainRegisterCtaLabel.value =
        props.accountData?.data?.main?.register?.cta?.label ||
        'Fill out the form';
      getMainLoginText.value =
        props.accountData?.data?.main?.login?.text ||
        'Are you already a registered user?';
      getMainLoginCtaLink.value =
        props.accountData?.data?.main?.login?.cta?.url || '/';
      getMainLoginCtaLabel.value =
        props.accountData?.data?.main?.login?.cta?.label ||
        'Log in to your account';
      getSections.value = props.accountData?.data?.sections || [];
    });

    return {
      handleAccountClick,
      handleAccountLogout,
      isAuthenticated,
      router,
      app,
      user,
      userGetters,
      getMainTitle,
      getMainIconDesktop,
      getMainIconMobile,
      getMainIconAlt,
      getMainRegisterText,
      getMainRegisterCtaLink,
      getMainRegisterCtaLabel,
      getMainLoginText,
      getMainLoginCtaLink,
      getMainLoginCtaLabel,
      getSections,
      toggleWishlistSidebar,
    };
  },
});
</script>

<style lang="scss">
.home-login-row {
  width: 100%;
  position: relative;
  background: var(--c-dark-grey);
  color: var(--c-white);
  .home-login-section-row {
    padding: 6rem 0;
    @include to-tablet-max {
      padding: 1rem 0 2.5rem 0;
    }
    @include to-mobile-max {
      display: var(--block);
    }
    .home-login-section-left {
      min-width: 19.25rem;
      max-width: 19.25rem;
      padding-right: 1.5rem;
      width: var(--w-full);
      @include to-mobile-max {
        min-width: 100%;
        max-width: 100%;
        padding: 0 0 1.563rem 0;
      }
      h2 {
        @include font-italic;
        margin-bottom: 1.125rem;
        color: var(--c-yellow);
        font-weight: normal;
        span {
          display: var(--block);
        }
        @include to-tablet-max {
          font-size: var(--font-size-24);
          line-height: var(--line-height-28);
          span {
            display: var(--block);
          }
        }
        label {
          margin-bottom: 0.75rem;
          @include to-tablet-max {
            margin-bottom: 0.5rem;
          }
          svg {
            max-width: 2.5rem;
            @include to-tablet-max {
              width: 1.563rem;
              height: var(--h-auto);
            }
          }
        }
      }
      p {
        margin-bottom: 1.688rem;
        max-width: 16.875rem;
        @include to-tablet-max {
          font-size: var(--font-size-13);
          line-height: var(--line-height-16);
        }
      }
      button {
        min-width: 12.938rem;
        font-size: var(--font-size-12);
        line-height: var(--line-height-12);
        padding: 0.688rem 1.25rem;
        text-transform: inherit;
        @include to-mobile-max {
          width: 100%;
          min-width: 100%;
        }
      }
      .registered-user {
        margin: 1.875rem 0 0.5rem 0;
        color: var(--c-yellow);
      }
    }
    .home-login-section-right {
      width: var(--w-full);
    }
    .home-login-box {
      @media (max-width: 81.25rem) {
        flex-wrap: var(--flex-wrap);
      }
      .home-login-col {
        width: 25%;
        text-align: center;
        position: var(--relative);
        padding: 0 15px;
        a {
          color: var(--c-white);
        }
        .image {
          background: var(--c-yellow);
          width: 12.938rem;
          height: 12.938rem;
          max-width: 12.938rem;
          margin: auto auto 1.5rem auto;
          transition: 0.7s;
          display: var(--flex);
          border-radius: 12.938rem;
          justify-content: center;
          align-items: var(--align-items);
          @include to-tablet-1500 {
            max-width: 9.75rem;
            width: 9.75rem;
            height: 9.75rem;
          }
        }
        &:nth-child(2) {
          @media (max-width: 81.25rem) {
            &::after {
              display: none;
            }
          }
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        &::after {
          content: '';
          border-right: solid 0.063rem var(--c-white);
          height: var(--h-full);
          position: var(--absolute);
          right: 0px;
          top: var(--top-0);
        }
        @media (max-width: 81.25rem) {
          width: 50%;
          padding: 0px 0.5rem;
          margin: 0.938rem 0;
        }
        p {
          font-size: var(--font-size-20);
          line-height: var(--line-height-26);
          b {
            color: var(--c-yellow);
            display: var(--block);
            font-weight: normal;
          }
          @media (max-width: 81.25rem) {
            &::after {
              content: '';
              border-bottom: solid 0.063rem var(--c-white);
              height: 0.063rem;
              width: var(--w-full);
              position: var(--absolute);
              right: 0px;
              top: -1.063rem;
            }
            &::before {
              content: '';
              border-bottom: solid 1px var(--c-white);
              height: 0.063rem;
              width: var(--w-full);
              position: var(--absolute);
              right: 0px;
              bottom: -0.938rem;
            }
          }
          @include to-mobile-max {
            font-size: var(--font-size-13);
            line-height: var(--line-height-16);
          }
        }
        svg,
        img {
          max-width: 6.375rem;
          @include to-tablet-1500 {
            max-width: 9.75rem;
          }
        }
        &:hover {
          svg,
          img {
            transition: 0.7s;
            transform: rotate(360deg);
          }
        }
      }
    }
  }
  &.login-with {
    background: var(--c-yellow);
    .divider-design {
      &::after {
        background: var(--c-dark-grey);
      }
    }
    .home-login-section-row {
      .btn-yellow {
        border-color: var(--c-dark-grey);
      }
      .home-login-section-left {
        h2 {
          color: var(--c-dark-grey);
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
      .home-login-box {
        .home-login-col {
          p {
            color: var(--c-dark-grey);
            &::after,
            &::before {
              border-color: var(--c-dark-grey);
            }
          }
          &::after {
            border-right: solid 0.063rem var(--c-dark-grey);
          }
          .image {
            background: var(--c-white);
          }
          &:nth-child(4) {
            &::after {
              @media (max-width: 81.25rem) {
                border-right: 0;
              }
            }
          }
          &:nth-child(5) {
            p {
              &::before {
                border: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
